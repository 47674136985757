import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './store/store'
import axios from 'axios'
import global from './utils/global.js'
import wx from 'weixin-js-sdk'

import wxShare from './utils/wxShare.js'
import VConsole from 'vconsole'
import '@mdi/font/css/materialdesignicons.css'
Vue.prototype.wxShare = wxShare;
Vue.prototype.wx = wx
Vue.prototype.global = global;
axios.defaults.withCredentials = true
Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.console = window.console

new VConsole();
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
