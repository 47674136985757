import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default  new Vuex.Store({
  state: {
    UserInfoValue:{
      name:'',
      password:'',
    },
    baseUrl:'',
  },
  getters: {
    getUserInfoValue: state => state.UserInfoValue,
    getBaseUrl: state => state.baseUrl
  },
  mutations: {
    setUserInfoValue(state,UserInfoValue){
      state.UserInfoValue = UserInfoValue
    },
    setBseUrl(state,baseUrl){
      state.baseUrl = baseUrl
    }
  },
  actions: {
  }
})
