/* eslint-disable */



const jsSDKAuth = 'https://webrtc.qq-kan.com/api/wxjssign/'; 
const signMap = new Map(); 

const defaultWxShareConfig = { 
	title: "Share Camera", 
	desc: 'Share Camera',
	link: location.href,
	imgUrl: 'https://webrtc.qq-kan.com/static/camera.png',
	jsApiList: [ 'checkJsApi','updateAppMessageShareData','updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
	hideMenuList: ['menuItem:editTag', 'menuItem:delete', 'menuItem:originPage', 'menuItem:readMode','menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email', 'menuItem:share:brand']
} 
/*
const wxShareConfig = {
	
}
*/
 
const wxShare = {
	updateWxShareConfig(config = {}) {
		console.log("updateWxShareConfig  config = ",config);
		var ShareConfig ={};
		this.wx = config.wx
		this.axios = config.axios
		ShareConfig.title = config.title || defaultWxShareConfig.title;
		ShareConfig.desc = config.desc || defaultWxShareConfig.desc;
        ShareConfig.link = config.link || defaultWxShareConfig.link;
		ShareConfig.imgUrl = config.imgUrl || defaultWxShareConfig.imgUrl;
        ShareConfig.jsApiList = config.jsApiList || defaultWxShareConfig.jsApiList;
        ShareConfig.hideMenuList = config.hideMenuList || defaultWxShareConfig.hideMenuList;


		console.log("updateWxShareConfig  link = ",ShareConfig.link);
		//var authUrl = wxShareConfig.link.split("#")[0];
		//authUrl = authUrl.split("?")[0];
		
		//if (signMap.has(authUrl)) {
		//	this._wxConfigJSSDK(signMap.get(authUrl), wxShareConfig);
	//	} else {
			if(config.axios!= null){
				this._wxShareAuth(ShareConfig);
			}
			
		//}
 
	},
	_wxShareAuth(shareConfig) {
		var that = this;
		//var url = jsSDKAuth + "?url=" + encodeURIComponent(authUrl);
		console.log("_wxShareAuth  authUrl = ",shareConfig.link);
		//console.log("_wxShareAuth  authUrl = ",url);
		var that = this;
		var  data = {url:encodeURIComponent(shareConfig.link)};  
		console.log("_wxShareAuth  data = ",data);
		this.axios.post(jsSDKAuth,data,{
		 headers: {
		  'Access-Control-Allow-Origin': "*",
		  'X-Xsrftoken':this.xsrf
		 }
		})
		.then(res=>{
			console.log('res=>',res); 
			
			if(res.data.state==200){

				const sign = res.data.data;
				//signMap.set(authUrl, sign);
				that._wxConfigJSSDK(shareConfig,sign);
							  
						 

			}else{
				console.log('res.data.states ',res.data.states ); 

			return;
		
			}
			
					
		})
		.catch(error=>{
			console.log(' http post res=> ------------------------ error ',error); 
			return;
		})
		

	},
	_wxConfigJSSDK(shareConfig,shareSign) {
		var that = this;
		console.log("_wxConfigJSSDK appId =",shareSign.appId);
		console.log("_wxConfigJSSDK timestamp =",shareSign.timestamp);
		console.log("_wxConfigJSSDK nonceStr =",shareSign.nonceStr);
		console.log("_wxConfigJSSDK signature=",shareSign.signature);
		console.log("_wxConfigJSSDK url=",shareSign.url);
		this.wx.config({
			debug: false,
			appId: shareSign.appId,
			timestamp: shareSign.timestamp,
			nonceStr: shareSign.nonceStr,
			signature: shareSign.signature,
			jsApiList:  shareConfig.jsApiList
		})
		this.wx.ready(function() {
			var self = that;
			console.log("_wxConfigJSSDK ready ");
		    const {title,desc,link,imgUrl} = shareConfig;
			console.log("_wxConfigJSSDK ready title = ",title);
			console.log("_wxConfigJSSDK ready desc = ",desc);
			console.log("_wxConfigJSSDK ready link = ",link);
			console.log("_wxConfigJSSDK ready imgUrl = ",imgUrl);

			self.wx.checkJsApi({
				jsApiList: ['updateAppMessageShareData','onMenuShareAppMessage','updateTimelineShareData','onMenuShareTimeline'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
				success: function(res) {
					console.log("checkJsApi::checkResult ",res);
				// 以键值对的形式返回，可用的api值true，不可用为false
				// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
				}
			  });

			if(self.wx.updateAppMessageShareData!=null)
			{
				self.wx.updateAppMessageShareData({
					title:shareConfig.title,
					desc:shareConfig.desc,
					link:shareConfig.link,
					imgUrl:shareConfig.imgUrl,
					success: function(data) {
						console.log("Share updateAppMessageShareData Sucessed ",data);
					},
					fail: function(error) {
						console.log("Share updateAppMessageShareData Failed ",error);
					},
					cancel: function(data) {
						console.log("Share updateAppMessageShareData Cancel ",data);
					}
				});
			}else{
				self.wx.onMenuShareAppMessage({
					title:shareConfig.title,
					desc:shareConfig.desc,
					link:shareConfig.link,
					imgUrl:shareConfig.imgUrl,
					success: function(data) {
						console.log("Share onMenuShareAppMessage  Sucessed ",data);
					},
					fail: function(error) {
						console.log("Share onMenuShareAppMessage Failed ",error);
					},
					cancel: function(data) {
						console.log("Share onMenuShareAppMessage Cancel ",data);
					}
				});

			}
			
			if(self.wx.updateTimelineShareData!=null){
				self.wx.updateTimelineShareData({
					title:shareConfig.title,
					link:shareConfig.link,
					imgUrl:shareConfig.imgUrl,
					success: function() {
						console.log("Share Time line Sucessed");
					},
					cancel: function() {
						console.log("Share Time line Cancel");
					}
				});

			}else{
				self.wx.onMenuShareTimeline({
					title:shareConfig.title,
					link:shareConfig.link,
					imgUrl:shareConfig.imgUrl,
					success: function() {
						console.log("Share Time line Sucessed");
					},
					cancel: function() {
						console.log("Share Time line Cancel");
					}
				})

			}
			
			
			
		});
		this.wx.error(function(res) {
			console.log("Share: config error  ", res);
		});
		
	}
}
export default wxShare;
