import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/register.vue')
  },
  {
    path: '/realvideo',
    name: 'realvideo',
    component: () => import('../components/realvideo.vue')
  },
  {
    path: '/adddevice',
    name: 'adddevice',
    component: () => import('../components/adddevice.vue')
  },
  {
    path: '/devicesetting',
    name: 'devicesetting',
    component: () => import('../components/devicesetting.vue')
  },{
    path: '/playlist',
    name: 'playlist',
    component: () => import('../components/playlist.vue')
  },{
    path: '/play',
    name: 'play',
    component: () => import('../components/play.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/', 
  routes,
})

export default router