<template>
  <v-app>  
        <!-- 如果使用 vue-router -->
 
        <router-view v-if="isRouterAlive"></router-view>
       
  </v-app>
</template>

<script>



export default {
    name: 'App',
    provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
            return {
                reload: this.reload                                              
            }
    },


 // components: {HelloWorld,},

    data() {
      return {
            timer: null,
            serverTimeoutObj:null,
            subscribeTimeoutObj:null,
            timeout:30000,
            path:"",
            socket:"",
            isAndroid:false,
            isIos:false,
            isChrome:false,
            isWeixin:false,
            meid:'',
            homeid:'',
            homesessionId:'',
            modeMap :null,
            isRouterAlive: true,
            }
  },
 
  
  sendMsg: function (data) {
     this.send(data);
  },
  created() {
     

     this.homeid = this.newGuid();
     this.meid = this.newGuid();
     this.homesessionId = this.newGuid();
     var ishttps = 'https:' == document.location.protocol ? true: false;
     console.log("App Created ",this.meid);
     //console.log("url--info",window.location.origin);
     //console.log("url--info",window.location.host);

 
    var host=localStorage.getItem('host');
    if(host!= null && host!=undefined&& host.length>0){
         console.log('[INFO]  host ',host);
    }
   
     
     if(window.location.hostname=="localhost"){
         this.global.setBaseUrl('https://webrtc.qq-kan.com');
         if(ishttps){
            this.path = 'wss://webrtc.qq-kan.com/wswebclient/'+this.meid; 
         }else{
            this.path = 'ws://webrtc.qq-kan.com/wswebclient/'+this.meid; 
        }
    

     }else{
         this.global.setBaseUrl(window.location.origin);
        if(ishttps){
            this.path = 'wss://'+window.location.host+'/wswebclient/'+this.meid; 
            localStorage.setItem('host',window.location.host);
        }else{
            this.path = 'ws://'+window.location.host+'/wswebclient/'+this.meid; 
            localStorage.setItem('host',window.location.host);
        }
     
     }    
     this.global.setMeId(this.meid);
     this.global.setHomeId(this.homeid);
     this.init();    
    },
    mounted(){
     // var that = this
      var u = window.navigator.userAgent;
      if(u!=null){
        this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //ios终端
        this.isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        this.isChrome =u.indexOf("Chrome") !== -1 //Chrome 浏览器
        this.isWeixin = u.indexOf('micromessenger') != -1;  //微信浏览器

        //if(this.isAndroid==false && this.isIos == false && this.isWeixin== false){
        if(navigator.permissions && navigator.mediaDevices){
          this.handlePermission();
        }else{
                        if(navigator.mediaDevices){                     
                        navigator.mediaDevices.getUserMedia({video: false, audio: true})
                        .then(function(stream) {
                            /* 使用这个stream stream */
                            //that.global.setStream(stream);
                            if(stream!= null){
                              stream.getTracks().forEach(track => {
                                  console.log('[INFO]  streamHandler getTracks ',track);
                                  });
                            }
          

                            console.log("[INFO] getUserMedia stream " ,stream);
                        })
                        .catch(function(err) {
                            console.log("[INFO] getUserMedia err " ,err);
                            /* 处理error */
                        });
                          }

        }
      }
    },
    destroyed() {
      if(this.socket!=null){
         this.socket.close();
         this.socket = null;
      }
      if(this.timer!=null){
                clearTimeout(this.timer);
                this.timer = null;
      }
      console.log("App Destroyed");
   },

   methods: {

            handlePermission() {
                 // var that = this
                    console.log("[INFO] handlePermission");
                    try {
                    navigator.permissions.query({name:'microphone'}).then(function(result) {
                    result.onchange = function() {      
                        console.log("[INFO] handlePermission  onchange " ,result.state);
                        if (result.state === 'granted') {
                              navigator.mediaDevices.getUserMedia({video: false, audio: true})
                                .then(function(stream) {
                                    /* 使用这个stream stream */
                                   // that.global.setStream(stream);
                            if(stream!= null){
                              stream.getTracks().forEach(track => {
                                  console.log('[INFO]  streamHandler getTracks ',track);
                                  });
                            }
                                })
                                .catch(function(err) {
                                    console.log("[INFO] getUserMedia err " ,err);
                                    /* 处理error */
                                });
                                }
                    }
                    
                    if (result.state === 'granted') {
                    console.log("[INFO] handlePermission  granted== " ,result.state);  
                      navigator.mediaDevices.getUserMedia({video: false, audio: true})
                        .then(function(stream) {
                            /* 使用这个stream stream */
                            //that.global.setStream(stream);
                               if(stream!= null){
                              stream.getTracks().forEach(track => {
                                  console.log('[INFO]  streamHandler getTracks ',track);
                                  });
                            }
                        })
                        .catch(function(err) {
                            console.log("[INFO] getUserMedia err " ,err);
                            /* 处理error */
                        });    
                  
                    
                    } else if (result.state === 'prompt') {
                        console.log("[INFO] handlePermission  prompt== " ,result.state);
                        navigator.mediaDevices.getUserMedia({video: false, audio: true})
                        .then(function(stream) {
                            /* 使用这个stream stream */
                            //that.global.setStream(stream);
                            if(stream!= null){
                              stream.getTracks().forEach(track => {
                                  console.log('[INFO]  streamHandler getTracks ',track);
                                  });
                            }
                        })
                        .catch(function(err) {
                            console.log("[INFO] getUserMedia err " ,err);
                            /* 处理error */
                        });
                    
                    } else if (result.state === 'denied') {
                
                    console.log("[INFO] handlePermission  denied== " ,result.state);
                   
                
                    }
                    
                });
                } catch (err) {
                console.log("[INFO] handlePermission  error = ",err);
                
                }
            },

            reload () {
                this.isRouterAlive = false;            
                this.$nextTick(function () {
                    this.isRouterAlive = true;       
                }) 
            },
            init: function () {
            if(typeof(WebSocket) === "undefined"){
                alert("您的浏览器不支持socket")
            }else{
                // 实例化socket
                this.socket = new WebSocket(this.path)
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
                this.socket.onclose = this.close
            }
        },
        open: function () {
            if(this.timer!=null){
                clearTimeout(this.timer);
                this.timer = null;
            }
             console.log("socket连接成功")
            this.global.setWebsocketOpenn(true);
            this.global.setWs(this.socket);
           this.global.Subscribe();          
            var that = this;
            this.serverTimeoutObj = setInterval(function(){
                that.global.sendToServer({
                    "eventName": "__ping",
                    "data": {
                        "sessionId": that.homeid,
                        "sessionType": "IE",
                        "messageId": that.newGuid(),
                        "from": that.meid,
                        "to": that.homeid
                        }
                });
                }, this.timeout);
        },
        error: function () {
              this.global.setWebsocketOpenn(false);
              this.global.setWs(null);
               if(this.serverTimeoutObj!= null){
                clearTimeout(this.serverTimeoutObj);
                this.serverTimeoutObj = null;
               }
            if(this.subscribeTimeoutObj!= null){
                clearTimeout(this.subscribeTimeoutObj);
                this.subscribeTimeoutObj = null;
            }
              this.timer = setTimeout(this.reconnect, 1000);
              console.log("连接错误")
        },
        getMessage: function (msg) {
           
          // console.log("App getMessage "+msg.data);
           const data = JSON.parse(msg.data);
           this.global.getMessage(data);

           
        },
        send: function (Data) {
            if(this.socket!= null && this.socket.readyState === 1){
                this.socket.send(Data);
            }
            
        },
        close: function () {
             console.log("socket已经关闭");
             this.global.setWebsocketOpenn(false);
            this.global.setWs(null);
            if(this.serverTimeoutObj!= null){
                clearTimeout(this.serverTimeoutObj);
                this.serverTimeoutObj = null;
            }

           if(this.subscribeTimeoutObj!= null){
                clearTimeout(this.subscribeTimeoutObj);
                this.subscribeTimeoutObj = null;
            }


            
            this.timer = setTimeout(this.reconnect, 1000);
           
        },
        reconnect: function () {
            if(this.timer!=null){
            clearTimeout(this.timer);
            this.timer = null;
            }
            this.meid = this.newGuid();
            console.log("App reconnect ",this.meid);
            var ishttps = 'https:' == document.location.protocol ? true: false;
            //  this.path = 'wss://webrtc.qq-kan.com/wswebclient/'+this.meid; 

            if(ishttps){
                this.path = 'wss://'+window.location.host+'/wswebclient/'+this.meid; 
                
            }else{
                this.path = 'ws://'+window.location.host+'/wswebclient/'+this.meid; 
            
            }

            this.global.setMeId(this.meid);
            this.init();
        },
        newGuid() {
                let s4 = function() {
                    return (65536 * (1 + Math.random()) | 0).toString(16).substring(1);
                }
                return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
       },
  },
};
</script>
