<template>
  

        <v-card  
          flat
          tile
          width=100%
          height=100%>
          <v-toolbar  color="indigo" dark >
             <v-icon size="32">mdi-webrtc</v-icon>
            <v-toolbar-title class="pl-1">WebRTC</v-toolbar-title>
             <v-spacer></v-spacer>
              <v-btn @click="onloginoutclick" icon>
               <v-icon>mdi-logout</v-icon>
            </v-btn>
            <template v-slot:extension>
              <v-btn
                color="pink"
                dark
                small
                absolute
                bottom
                left
                fab
                @click="onadddevice"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-toolbar>
          <v-card-text style="height: 25px;" class="grey lighten-5"></v-card-text>
          <v-container >

           <v-row dense>
        <v-col
          v-for="data in datas"
          :key="data.serno"
          :cols="12"
        >
          <v-card>
            <v-img
              :src="data.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
              @click="oncartclick(data)"
            >
              <v-card-title v-text="data.name"></v-card-title>
            </v-img>
             
            <v-card-actions>
              <v-card-text class="pl-1">
                <v-icon :color="data.state==='online'?'green':'grey'">mdi-brightness-1</v-icon>
                <span class="ml-2"> {{data.state}}</span>  
            </v-card-text>
          
              <v-btn @click="onplay(data)" icon>
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn @click="onshare(data)" icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
              <v-btn @click="ondevicesetting(data)" icon>
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
            </v-container>
        </v-card>


</template>

<script>

import { Base64 } from 'js-base64'
  export default {
    name: 'Home',
    inject:['reload'],
    data: () => ({
       xsrf:'',
        datas: [],
       userinfo:null,
       reloaded:false,
       isWeixinminiProgram:false,
       homeId:"",
    }),
    beforeCreate(){
   
       
    },
    created() {  
               var xsrf = this.getCookie("_xsrf")
             if(xsrf!=""){
                var xsrflist  = xsrf.split("|");
              
               // console.log("home xsrflist ->",xsrflist[0])
                this.xsrf = Base64.decode(xsrflist[0])
               // console.log("home xsrf ->",this.xsrf)
                
             } 
      
      console.log(this.$route.query);
   
    var querytype = this.getQueryString("type");
     if(querytype!= null){
        console.log("Home querytype = ",querytype);
        if(querytype=="share"){

             console.log("Home querytype = ",querytype);
        }

         
     }

     var weixinusercode = this.$route.query.weixinusercode;
      if(weixinusercode!=null){
                    console.log("Home mounted  weixinusercode: "+weixinusercode);
      }
      this.homeId = this.global.getHomeId();
      this.global.AddModeMap(this.homeId,this.getMessage);
      var UserInfo = localStorage.getItem('userinfo')
      if(UserInfo!=null && UserInfo!= undefined && UserInfo.length>0){
           var obj = JSON.parse(UserInfo);
           // console.log("Home created  user: "+obj.user);
           if(obj.user  == ''){             
                     this.$router.push('Login')                
           }else if(obj.user ==undefined){           
               this.$router.push('Login')            
           }else{
             
          this.userinfo = obj;
          //console.log("Home created   ",this.userinfo);
          var  getdataPromise  = this.getData();
          if (getdataPromise) {
                          getdataPromise.then(() => {
                           
                            console.log("getData");

                          }).catch((e) => {
                              
                              console.log("getData error",e.message);
                          });
          }

 
             
           } 
      }else{
            this.$router.push('Login')
      }
      
  
      
    },
    mounted(){

       var that = this;
       this.wx.miniProgram.getEnv(function(res) {
             
             console.log("Home miniProgram  ------------------ ",res) // true
             that.isWeixinminiProgram =res.miniprogram
             if(res.miniprogram==true){
               /*
               if(that.global.getWeixinConfig()==false){
                   that.global.setWeixinConfig(true);
                   that.wxinitshare();
               }
               */
             
              console.log("Home miniProgram  ------------------ ",res.miniprogram) // true
               if (window.WeixinJSBridge!=null){
                  window.WeixinJSBridge.on('onPageStateChange', function(res) {
 
                        console.log('Home miniProgram onPageStateChange  is active', res.active);
                });
               }


               if (window.WeixinJSBridge !=null){
                if( document.addEventListener ){
                  //  console.log('Home  addEventListener  ------- WeixinJSBridgeReady');
                    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {

                         console.log('Home  WeixinJSBridgeReady  -------===============================>>>>>><<<<<<<< ');
/*
                             window.WeixinJSBridge.on('menu:share:appmessage', function onShareapp(argv){
                                    console.log("Home menu:share:appmessage  ------------------ ",argv) // true
                            });
                            */
                         
                    }, false);
                }else if (document.attachEvent){
                  console.log('Home  attachEvent  ------- WeixinJSBridgeReady');
                    document.attachEvent('WeixinJSBridgeReady',function onBridgeReady() {
                       console.log('Home  WeixinJSBridgeReady  ------- ');
                    }); 
                    document.attachEvent('onWeixinJSBridgeReady', function onBridgeReady() {
                       console.log('Home  onWeixinJSBridgeReady  ------- ');
                    });
                }
              }else{
                  console.log('Home miniProgram WeixinJSBridgeReady  undefined ');
                //this.onBridgeReady();
              }

               

             }
             

      })

        var datas = localStorage.getItem('datas')
         if(datas!=null && datas!= undefined && datas.length>0){
           this.datas = JSON.parse(datas);
 
           this.$forceUpdate();
           //console.log('beforeCreate datas=>',datas); 
         }
    },
    destroyed() {
      this.global.DelModeMap(this.homeId);
    },
    methods: {
      getQueryString(name) {
          var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
          var reg_rewrite = new RegExp("(^|/)" + name + "/([^/]*)(/|$)", "i");
          var r = window.location.search.substr(1).match(reg);
          var q = window.location.pathname.substr(1).match(reg_rewrite);
          if(r != null){
            return unescape(r[2]);
          }else if(q != null){
            return unescape(q[2]);
          }else{
            return null;
          }
      },
      wxinitshare:function(){          
               
        if(this.isWeixinminiProgram==true){

           
            
       // var uuid = this.newGuid();
        var shareUrl =location.href;
        if(this.wxShare!=null){
        console.log("home::updateWxShareConfig");
        this.wxShare.updateWxShareConfig({
              title:'标题',
              desc: '简介',
              link: shareUrl,
              jsApiList:[ 'checkJsApi','updateAppMessageShareData','onMenuShareAppMessage','updateTimelineShareData','onMenuShareTimeline'],
              axios:this.axios,
              wx:this.wx
          });
         }else{
            console.log("shareCamera  wxShare is null");
         }
        }else{
          console.log("shareCamera  isWeixinminiProgram is false");

        }
        

        },
        onshare:function(item){
         if(this.isWeixinminiProgram==true){

            

            console.log("Home shareCamera   ",item.serno);
            var path = '/share/share?'+"serno="+item.serno+"&name="+item.name;
        

            
            this.wx.miniProgram.navigateTo({url: path})
           
            
         }

      },

     getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      getData () {
            let that = this;
            var sessionon = localStorage.getItem('sessionon')
            
  
            if(this.global.getInitData()== false){
               this.global.setInitData(true);
               
               let data = {"user":this.userinfo.user,"pwd":this.userinfo.pwd};
                
               this.axios.post('/api/getdevice',data,{
                 headers: {
                  'Access-Control-Allow-Origin': "*",
                  'Authorization': sessionon,
                  'X-Xsrftoken':this.xsrf
                 }
                })
                .then(res=>{
                   // console.log('res=>',res.data); 
                    
                    if(res.data.state==200){
                         var subscribes = ''
                          that.datas =  this.sortlist(res.data.payload.datas) 
                            this.datas.forEach(item =>{
                                if(item.src!= null  && item.src!=undefined){
                                      if(item.src==''){
                                          item.src = require('../assets/back.jpg')

                                }
                                }
                                if(item.serno!= null  && item.serno!=undefined){
                                  if(subscribes ==''){
                                    subscribes = item.serno;
                                  }else{
                                      subscribes+=",";
                                      subscribes+=item.serno;
                                  }                                
                                }

                                
                               // console.log(item);
                          });
                           console.log('home  __subscribe');
                           this.global.sendToServer({
                            "eventName": "__subscribe",
                            "data": {
                              "sessionId": this.homeId,
                              "sessionType": "IE",
                              "messageId": this.newGuid(),
                              "from": this.global.getMeId(),
                              "to": subscribes
                              }
                              });
                              
                          
                          localStorage.setItem('datas',JSON.stringify(that.datas));
                          //console.log('datas=>',that.datas);
                          that.$forceUpdate();
                    }else if(res.data.state==300){
                          this.$router.push('Login')                       
                    }
                    
                            
                })
                .catch(error=>{
                       console.log('error=>',error);
                })
             }

     
     },
      getMessage: function (msg) {
        
        if(msg!= null && msg.data!= null){
          if(msg.data.sessionId!= this.homeId){
            return ;
          }
        }
        
        //console.log("Home getMessage  ------------------- ",msg.eventName);
        switch(msg.eventName) {
										case "_login":
                      this.handleLoginMsg(msg.data);
                       break;
										case "_logout":
                      this.handleLogoutMsg(msg.data);
                      break;
                    case "_publish":
                      this.handlePublishMsg(msg.data);
                      break;										
                     default:
                       console.log("Home Got default message", msg);
                    break;
            }

       
        
      },
      handleLoginMsg(data) {
         if(this.datas.length>0){        
         this.datas.forEach(item =>{
           
                if(item.serno==data.from){
                   item.state="online";
                }
            
         }); 
        this.datas = this.sortlist(this.datas);
        this.$forceUpdate();
         }
       },
      handleLogoutMsg(data) {
         if(this.datas.length>0){  
          this.datas.forEach(item =>{
           
                if(item.serno==data.from){
                   item.state="offline";
                }
            
         }); 
         this.datas = this.sortlist(this.datas);
         this.$forceUpdate();
         }
       },
      handlePublishMsg(data) {
          console.log("Home handlePublishMsg   "+data.message);
       },
      oncartclick: function(item){
        if(item!=null){
          if(this.global.getWebsocketOpenn()){
           console.log("Home oncartclick   "+item.serno);
           this.$router.push({ name: 'realvideo',params:{serno:item.serno,name:item.name} })
          }
 
        }

           
      },
      onloginoutclick: function(){
        var  UserInfo = {user:'',pwd:''};
        localStorage.setItem('userinfo',JSON.stringify(UserInfo));
        localStorage.setItem('datas',"");
        this.global.setInitData(false);
        this.global.setWebSessionon("");
        this.$router.push('login');
      },
      onadddevice: function(){
         this.$router.push('adddevice')
      },
      ondevicesetting: function(item){
         console.log("Home ondevicesetting   "+item.serno);
         this.$router.push({ name: 'devicesetting',params:{serno:item.serno,name:item.name,type:item.type} })
      },
      onplay: function(item){
         console.log("Home onplay   "+item.serno);
         this.$router.push({ name: 'playlist',params:{serno:item.serno,name:item.name} })
      },
      newGuid() {
                let s4 = function() {
                    return (65536 * (1 + Math.random()) | 0).toString(16).substring(1);
                }
                return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
       },
       sortlist(datas){
       //  console.log("Home sortlist   ",datas);
         var data1  = new Array();
         var data2  = new Array();
         datas.forEach(item =>{
           //console.log("Home sortlist   ",item);
                if(item.state=="online"){
                      data1 = data1.concat(item);
                }else{
                  data2 = data2.concat(item);                   
                }
            
         });  
         data1=data1.concat(data2);
          // console.log("Home sortlist  end ",data1);
         return data1
       },
    },
  }
</script>
<style scoped>

.rounded-card{
    border-radius:50px;
}
</style>